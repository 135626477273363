$env: stage;
@import '@/styles/_component-base.styles.scss';

.listItem {
    display: inline-block;
    font-size: set-fontSize(13);

    &:first-of-type {
        .arrowDivider {
            padding-left: 0;
        }

        .slashDivider {
            @include breakpoint(md) {
                display: none;
            }
        }
    }

    &:last-of-type {
        display: none;

        @include breakpoint(md) {
            display: block;
        }
    }
}

.list {
    margin: spacer(2xs) 0;
    display: flex;
}

.text {
    color: color(text, headingDark);

    &:visited {
        color: color(text, headingDark);
    }
}

.link:hover {
    color: color(cta, base);
}

.slashDivider {
    margin: 0 0.43rem;
    display: inline-block;
    transform: scale(1, 1.6) translate(0px, -0.7px) rotate(23deg);
    color: #bebebe;
    cursor: default;
}

.arrowDivider {
    padding: 0 spacer(2xs);
}

