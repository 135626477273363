$env: stage;
@import '@/styles/_component-base.styles.scss';

.valueProps {
    &-transparent {
        background-color: transparent;
    }

    &-contrast-1 {
        background-color: color(contrast, 1);
    }
    
    .row {
        padding: spacer(xs) 8px;
        min-height: spacer(7xl);

        @include only-breakpoint(xs){
            margin: 0;
            width: 100%;
        }
    }
}

.valueProp {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: spacer(md);
    padding: spacer(md) spacer(2xs);
    margin: spacer(sm) 0;
    max-width: 252px;

    font-weight: $fontWeight-base;
    color: color(text, headingDark);
    font-size: set-fontSize(16);

    @include only-breakpoint(xs){
        line-height: 1.4;
    }

    @include breakpoint(lg){
        font-size: set-fontSize(20);
    }
}

.iconWrapper {
    width: 73px;
    height: 73px;
    background-color: #f8f8f8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    @include breakpoint(lg) {
        width: 100px;
        height: 100px;
    }
}

.icon {
    width: 60px;
    height: 60px;
    color: color(darkContrast, 1);
}

.link {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacer(md);
    text-decoration: none;
    color: color(text, headingDark);

    &:hover, &:focus {
        color: color(text, headingDark);
        text-decoration: underline;

        .icon {
            stroke: rgb(164, 164, 164);
        }
    }
}
